import React from 'react';
import Layout from "../../components/Layout";
import SEO from '../../components/seo';
import BlogArticlesWrapper from './components/BlogArticlesWrapper'
import EducatorInFocusArticlesWrapper from './components/EducatorInFocusArticlesWrapper'
import CustomBreadcrumbs from "../../components/CustomBreadcrumbs"
import styles from './styles.module.scss';

const Blog = ({
    pageContext: {
      breadcrumb: { crumbs },
    },
}) => (
  <Layout hideSidebar={true}>
    <SEO title="Academic Info | Student Guide" />

    <CustomBreadcrumbs crumbs={crumbs} />

    <section className={styles.studentGuide}>
      <h1 className={styles.pageTitle}>
        AcademicInfo Blog
      </h1>

      <p className={styles.pageText}> </p>

      <BlogArticlesWrapper />

      <EducatorInFocusArticlesWrapper />

    </section>
  </Layout>
);

export default Blog;
